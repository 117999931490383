@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lato:900&display=swap);
body,
html {
  margin: 0px;
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
  background-color: #333333;
}

.App {
  text-align: center;
  font-size: "16px";
}

.App-header {
  min-height: calc(100vh - 80px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(16px);
  font-weight: 300;
  color: rgba(255, 255, 255, 1);
}

.App-link {
  color: #ffed04;
  font-weight: 400;
  font-size: 1.3rem;
}

.logo-font {
  font-family: "Lato", sans-serif;
  font-size: 3rem;
  line-height: 2.8rem;
}

.App-logo {
  top: -10px;
  left: 0px;
  max-width: 150px;
  max-height: 80px;
  pointer-events: none;
}

@media (min-width: 500px) {
  .logo-font {
    font-family: "Lato", sans-serif;
    font-size: 5rem;
    line-height: 4.8rem;
  }

  .App-logo {
    max-width: 200px;
    max-height: 80px;
    pointer-events: none;
  }
}

